import './areas.scss';

const Areas = ({ areas, background }) => (
  <section className="areas" style={{ backgroundImage: `url(${background})` }}>
    <div className="container">
      <h2 className="section-title">Areas We Serve</h2>
      <ul>
        {areas?.map((el, index) => (
          <li key={index}>{el.area}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Areas;
