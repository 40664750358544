import './about.scss';

const About = ({ about, title }) => (
  <section className="about">
    <div className="container">
      <div className="about__content">
        <h2 className="section-title">Certified {title} Technicians</h2>

        <div dangerouslySetInnerHTML={{ __html: about?.content }} />
      </div>

      <div className="about__image" style={{ backgroundImage: `url(${about?.image})` }} />
    </div>
  </section>
);

export default About;
