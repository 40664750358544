import { useState, useEffect } from 'react';

import './brands.scss';

const Brands = ({ brands }) => {
  const screenWidth = window.screen.width;

  const [slide, setSlide] = useState();
  const [buttons, setButtons] = useState({
    prev: false,
    next: true,
  });

  const onNextClick = () => {
    setSlide([...brands.slice(screenWidth < 690 ? 27 : 25, brands.length)]);
    setButtons({
      prev: true,
      next: false,
    });
  };

  const onPrevClick = () => {
    setSlide([...brands.slice(0, screenWidth < 690 ? 27 : 25)]);
    setButtons({
      prev: false,
      next: true,
    });
  };

  useEffect(() => {
    if (brands.length) setSlide([...brands.slice(0, screenWidth < 690 ? 27 : 25)]);
  }, [brands]);

  return (
    <section className="brands">
      <div className="container">
        <h2 className="section-title">We fix all makes & models</h2>

        <div className="brands__nav">
          <button onClick={onPrevClick} className="button prev" disabled={!buttons.prev} aria-label="Prev Slide" />

          <button onClick={onNextClick} className="button next" disabled={!buttons.next} aria-label="Next Slide" />
        </div>

        <ul className="brands__slider">
          {slide?.map((el) => (
            <li key={el.ID}>
              <img src={el.url} alt={`${el.alt} Appliance Repair`} />
            </li>
          ))}
        </ul>

        <ul className="brands__list">
          {brands?.map((el) => (
            <li key={el.ID}>{el.alt} Appliance Repair</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Brands;
