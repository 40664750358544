import { Helmet } from 'react-helmet';

const Seo = ({ data, code }) => (
  <Helmet>
    <title>{data?.title}</title>
    <meta name="description" content={data?.description} />
    <meta property="og:title" content={data?.og_title} />
    <meta property="og:type" content={data?.og_type} />
    <meta property="og:description" content={data?.og_description} />

    {code && <script async src={`https://www.googletagmanager.com/gtag/js?id=${code}`}></script>}
    <script>
      {code &&
        `
        window.dataLayer = window.dataLayer || [];

        function gtag(){dataLayer.push(arguments);}

        gtag('js', new Date());

        gtag('config', '${code}');
      `}
    </script>
  </Helmet>
);

export default Seo;
