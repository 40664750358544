import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import './header.scss';

const Header = ({ phone, logo }) => {
  const [shadow, setShadow] = useState(false);
  const [location, setLocation] = useState();

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      case 'portland':
        setLocation('PORTLAND');
        break;
      case 'lv':
        setLocation('LAS VEGAS');
        break;
      case 'la':
        setLocation('LOS ANGELES');
        break;
      case 'denver':
        setLocation('DENVER');
        break;
      case 'dallas':
        setLocation('DALLAS');
        break;
      case 'austin':
        setLocation('AUSTIN');
        break;
      case 'sacramento':
        setLocation('SACRAMENTO');
        break;
      case 'philly':
        setLocation('PHILADELPHIA');
        break;
      case 'oc':
        setLocation('ORANGE COUNTY');
        break;
      case 'orlando':
        setLocation('ORLANDO');
        break;
      case 'seattle':
        setLocation('SEATTLE');
        break;
      default:
        setLocation('SAN DIEGO');
        break;
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="topline">
        <div className="container">
          <p>SERVING {location} AND SURROUNDING AREAS</p>

          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      </div>

      <div className="line">
        <div className="container">
          <NavLink to="/" className="page-header__logo">
            <img src={logo} alt="Big City Appliance Repair" />
          </NavLink>

          <div className="links">
            <a href={`tel:${phone}`}>{phone}</a>

            <NavLink to="/book-now" className="button" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
              Book Now
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
