import { NavLink } from 'react-router-dom';

import './hero.scss';

const Hero = ({ title, phone, badges }) => (
  <section className="hero">
    <div className="hero__inner">
      <div className="hero__title">
        <div>
          <h1>
            Rated #1
            <br />
            {title} Service
          </h1>

          <p>Your Local Professionals</p>

          <div className="hero__badges">
            {badges.map((el, index) => (
              <img src={el} alt={`Badge #${index}`} key={index} />
            ))}
          </div>

          <div className="hero__links">
            <NavLink to="/book-now" className="button" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
              Book Now
            </NavLink>

            <span>
              or call us <a href={`tel:${phone}`}>{phone}</a>
            </span>
          </div>
        </div>
      </div>

      <div className="hero__rating">
        <div className="hero__rating--inner">
          <a
            href="https://goo.gl/maps/hmusq4oFQSneMv716"
            rel="noReferrer"
            target="_blank"
            onClick={(e) => e.preventDefault()}
          >
            <div>
              <img src="./images/g-logo.svg" alt="Google Rating" />
            </div>
            <div>
              <span>Google Rating</span>
              <img src="./images/red-stars.svg" alt="Red Stars" />
            </div>
          </a>

          <a href="https://bbb.com" rel="noReferrer" target="_blank" onClick={(e) => e.preventDefault()}>
            <div>
              <img src="./images/bbb-logo.svg" alt="BBB Rating" />
            </div>
            <div>
              <span>BBB Rating</span>
              <img src="./images/red-stars.svg" alt="Red Stars" />
            </div>
          </a>

          <a
            href="https://www.yelp.com/biz/big-city-ac-heating-and-appliance-repair-san-diego"
            rel="noReferrer"
            target="_blank"
            onClick={(e) => e.preventDefault()}
          >
            <div>
              <img src="./images/yelp-logo.svg" alt="Yelp Rating" />
            </div>
            <div>
              <span>Yelp Rating</span>
              <img src="./images/red-stars.svg" alt="Red Stars" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
