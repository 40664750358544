import { NavLink } from 'react-router-dom';

import './cta.scss';

const CallToAction = ({ phone }) => (
  <section className="cta">
    <div className="container">
      <h2 className="section-title">Need Your Home Appliances Fixed?</h2>

      <div className="cta__links">
        <NavLink to={`/book-now`} state={{ prevLocation: window.location.pathname.split('/')[1] }} className="button">
          Book Now
        </NavLink>

        <span>
          or call us <a href={`tel:${phone}`}>{phone}</a>
        </span>
      </div>
    </div>
  </section>
);

export default CallToAction;
