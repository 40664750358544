import { NavLink } from 'react-router-dom';

import './services.scss';

const Services = ({ services }) => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">Appliance Repair Services</h2>

      <div className="services__list">
        {services
          .sort(function (a, b) {
            if (a.title < b.title) return -1;

            return 0;
          })
          .map((el, index) => (
            <NavLink
              key={index}
              to={`/${el.slug}`}
              className="services__list--item"
              state={{ prevLocation: window.location.pathname.split('/')[1] }}
            >
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>

              <h4>
                {el.title.split(' ')[0]}
                <br />
                {el.title.split(' ')[1]}
              </h4>
            </NavLink>
          ))}
      </div>
    </div>
  </section>
);

export default Services;
