import { useState, useEffect } from 'react';
import { Layout, Seo } from '@components';

const ThankYou = ({ title, phones, email, logos, seo }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [gaCode, setGaCode] = useState();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      case 'portland':
        setPhoneNumber(phones.portland);
        setGaCode(seo.portland);
        break;
      case 'lv':
        setPhoneNumber(phones.lv);
        setGaCode(seo.las_vegas);
        break;
      case 'la':
        setPhoneNumber(phones.los_angeles);
        setGaCode(seo.los_angeles);
        break;
      case 'denver':
        setPhoneNumber(phones.denver);
        setGaCode(seo.denver);
        break;
      case 'dallas':
        setPhoneNumber(phones.dallas);
        setGaCode(seo.dallas);
        break;
      case 'austin':
        setPhoneNumber(phones.austin);
        setGaCode(seo.austin);
        break;
      case 'sacramento':
        setPhoneNumber(phones.sacramento);
        setGaCode(seo.sacramento);
        break;
      case 'philly':
        setPhoneNumber(phones.philadelphia);
        setGaCode(seo.philadelphia);
        break;
      case 'oc':
        setPhoneNumber(phones.oc);
        setGaCode(seo.orange_county);
        break;
      case 'orlando':
        setPhoneNumber(phones.orlando);
        setGaCode(seo.orlando);
        break;
      case 'seattle':
        setPhoneNumber(phones.seattle);
        setGaCode(seo.seattle);
        break;
      default:
        setPhoneNumber(phones.sd);
        setGaCode(seo.san_diego);
        break;
    }
  }, [title]);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title={title}>
      <Seo data={{ title: 'Thank You Page' }} code={gaCode} />

      <section className="thank-you">
        <div className="title">Thank you!</div>
        <div className="subtitle">We’ll respond to your request shortly!</div>

        <p>
          Big City has the best customers! We truly appreciate your business, and we’re so grateful for the trust you’ve
          placed in us. We look forward to serving you!
        </p>
      </section>
    </Layout>
  );
};

export default ThankYou;
