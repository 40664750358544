import { useState, useEffect, lazy } from 'react';
import { Layout, Seo } from '@components';

import { Hero } from '@sections';

const WhyWeLazy = lazy(() => import('../sections/WhyWe/WhyWe'));
const ServicesLazy = lazy(() => import('../sections/Services/Services'));
const SatisfactionLazy = lazy(() => import('../sections/Satisfaction/Satisfaction'));
const AboutLazy = lazy(() => import('../sections/About/About'));
const ReviewsLazy = lazy(() => import('../sections/Reviews/Reviews'));
const AreasLazy = lazy(() => import('../sections/Areas/Areas'));
const BrandsLazy = lazy(() => import('../sections/Brands/Brands'));
const CallToActionLazy = lazy(() => import('../sections/CallToAction/CallToAction'));

const PageTemplate = ({ title, about, phones, email, logos, why_we, reviews, services, areas, brands, seo }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [areasList, setAreasList] = useState();
  const [areaBg, setAreaBg] = useState();
  const [content, setContent] = useState();
  const [reviewsList, setReviewsList] = useState();
  const [seoData, setSeoData] = useState();
  const [whyWe, setWhyWe] = useState();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      case 'portland':
        setPhoneNumber(phones.portland);
        setAreasList(areas.portland);
        setAreaBg(areas.background_image.portland);
        setContent(about.portland);
        setReviewsList(reviews.portland);
        setSeoData(seo.portland);
        setWhyWe(why_we.portland);
        break;
      case 'lv':
        setPhoneNumber(phones.lv);
        setAreasList(areas.las_vegas);
        setAreaBg(areas.background_image.las_vegas);
        setContent(about.las_vegas);
        setReviewsList(reviews.las_vegas);
        setSeoData(seo.las_vegas);
        setWhyWe(why_we.lv);
        break;
      case 'la':
        setPhoneNumber(phones.los_angeles);
        setAreasList(areas.los_angeles);
        setAreaBg(areas.background_image.los_angeles);
        setContent(about.los_angeles);
        setReviewsList(reviews.los_angeles);
        setSeoData(seo.los_angeles);
        setWhyWe(why_we.los_angeles);
        break;
      case 'denver':
        setPhoneNumber(phones.denver);
        setAreasList(areas.denver);
        setAreaBg(areas.background_image.denver);
        setContent(about.denver);
        setReviewsList(reviews.denver);
        setSeoData(seo.denver);
        setWhyWe(why_we.denver);
        break;
      case 'dallas':
        setPhoneNumber(phones.dallas);
        setAreasList(areas.dallas);
        setAreaBg(areas.background_image.dallas);
        setContent(about.dallas);
        setReviewsList(reviews.dallas);
        setSeoData(seo.dallas);
        setWhyWe(why_we.dallas);
        break;
      case 'austin':
        setPhoneNumber(phones.austin);
        setAreasList(areas.austin);
        setAreaBg(areas.background_image.austin);
        setContent(about.austin);
        setReviewsList(reviews.austin);
        setSeoData(seo.austin);
        setWhyWe(why_we.austin);
        break;
      case 'sacramento':
        setPhoneNumber(phones.sacramento);
        setAreasList(areas.sacramento);
        setAreaBg(areas.background_image.sacramento);
        setContent(about.sacramento);
        setReviewsList(reviews.sacramento);
        setSeoData(seo.sacramento);
        setWhyWe(why_we.sacramento);
        break;
      case 'philly':
        setPhoneNumber(phones.philadelphia);
        setAreasList(areas.philadelphia);
        setAreaBg(areas.background_image.philadelphia);
        setContent(about.philadelphia);
        setReviewsList(reviews.philadelphia);
        setSeoData(seo.philadelphia);
        setWhyWe(why_we.philadelphia);
        break;
      case 'oc':
        setPhoneNumber(phones.oc);
        setAreasList(areas.orange_county);
        setAreaBg(areas.background_image.orange_county);
        setContent(about.oc);
        setReviewsList(reviews.orange_county);
        setSeoData(seo.orange_county);
        setWhyWe(why_we.oc);
        break;
      case 'orlando':
        setPhoneNumber(phones.orlando);
        setAreasList(areas.orlando);
        setAreaBg(areas.background_image.orlando);
        setContent(about.orlando);
        setReviewsList(reviews.orlando);
        setSeoData(seo.orlando);
        setWhyWe(why_we.orlando);
        break;
      case 'seattle':
        setPhoneNumber(phones.seattle);
        setAreasList(areas.seattle);
        setAreaBg(areas.background_image.seattle);
        setContent(about.seattle);
        setReviewsList(reviews.seattle);
        setSeoData(seo.seattle);
        setWhyWe(why_we.seattle);
        break;
      default:
        setPhoneNumber(phones.sd);
        setAreasList(areas.san_diego);
        setAreaBg(areas.background_image.san_diego);
        setContent(about.san_diego);
        setReviewsList(reviews.san_diego);
        setSeoData(seo.san_diego);
        setWhyWe(why_we.sd);
        break;
    }
  }, [title]);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title={title}>
      <Seo data={seoData} />
      <Hero title={title} phone={phoneNumber} badges={logos.badges} />
      <WhyWeLazy title={title} data={whyWe} services={services} />
      <ServicesLazy services={services} />
      <SatisfactionLazy />
      <AboutLazy title={title} about={content} />
      <ReviewsLazy reviews={reviewsList} />
      <AreasLazy areas={areasList} background={areaBg} />
      <BrandsLazy brands={brands} />
      <CallToActionLazy phone={phoneNumber} />
    </Layout>
  );
};

export default PageTemplate;
