import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Layout } from '@components';

import { Form } from '@sections';

const BookNow = ({ email, phones, logos, services }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const location = useLocation();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      case 'portland':
        setPhoneNumber(phones.portland);
        break;
      case 'lv':
        setPhoneNumber(phones.lv);
        break;
      case 'la':
        setPhoneNumber(phones.la);
        break;
      case 'denver':
        setPhoneNumber(phones.denver);
        break;
      case 'dallas':
        setPhoneNumber(phones.dallas);
        break;
      case 'austin':
        setPhoneNumber(phones.austin);
        break;
      case 'sacramento':
        setPhoneNumber(phones.sacramento);
        break;
      case 'philly':
        setPhoneNumber(phones.philadelphia);
        break;
      case 'oc':
        setPhoneNumber(phones.oc);
        break;
      case 'orlando':
        setPhoneNumber(phones.orlando);
        break;
      default:
        setPhoneNumber(phones.sd);
        break;
    }
  }, []);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title="Book Now">
      <Form preSelect={location.state?.prevLocation} services={services} />
    </Layout>
  );
};

export default BookNow;
