import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { Spinner } from '@components';

import PageTemplate from '@pages/PageTemplate';
import LegalPage from '@pages/LegalPage';
import BookNow from '@pages/BookNow';
import ThankYou from '@pages/ThankYou';

export const ADMIN_URL = 'https://admin.bigcityhomeservice.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages`);

  const settings = useFetch(`${ADMIN_URL}/general_settings?slug=settings-sd`);

  useEffect(() => {
    if (error || settings.error) toast.error('Data loading error. Please reload the page!');
  }, [error, settings.error]);

  if (isLoading || settings.isLoading) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/appliance-repair" />} />

      {data?.map((page) => (
        <Route
          key={page.id}
          path={`/${page.slug}`}
          element={
            <PageTemplate
              seo={page.acf.seo}
              title={page.title.rendered}
              about={{
                san_diego: {
                  content: page.acf.service_about_content.san_diego,
                  image: page.acf.service_about_image.san_diego,
                },
                portland: {
                  content: page.acf.service_about_content.portland,
                  image: page.acf.service_about_image.portland,
                },
                las_vegas: {
                  content: page.acf.service_about_content.las_vegas,
                  image: page.acf.service_about_image.las_vegas,
                },
                los_angeles: {
                  content: page.acf.service_about_content.los_angeles,
                  image: page.acf.service_about_image.los_angeles,
                },
                denver: {
                  content: page.acf.service_about_content.denver,
                  image: page.acf.service_about_image.denver,
                },
                dallas: {
                  content: page.acf.service_about_content.dallas,
                  image: page.acf.service_about_image.dallas,
                },
                austin: {
                  content: page.acf.service_about_content.austin,
                  image: page.acf.service_about_image.austin,
                },
                sacramento: {
                  content: page.acf.service_about_content.sacramento,
                  image: page.acf.service_about_image.sacramento,
                },
                philadelphia: {
                  content: page.acf.service_about_content.philadelphia,
                  image: page.acf.service_about_image.philadelphia,
                },
                oc: {
                  content: page.acf.service_about_content.orange_county,
                  image: page.acf.service_about_image.orange_county,
                },
                orlando: {
                  content: page.acf.service_about_content.orlando,
                  image: page.acf.service_about_image.orlando,
                },
                seattle: {
                  content: page.acf.service_about_content.seattle,
                  image: page.acf.service_about_image.seattle,
                },
              }}
              phones={{
                sd: settings.data[0].acf.phone_san_diego,
                lv: settings.data[0].acf.phone_las_vegas,
                portland: settings.data[0].acf.phone_portland,
                la: settings.data[0].acf.phone_los_angeles,
                denver: settings.data[0].acf.phone_denver,
                dallas: settings.data[0].acf.phone_dallas,
                austin: settings.data[0].acf.phone_austin,
                sacramento: settings.data[0].acf.phone_sacramento,
                philadelphia: settings.data[0].acf.phone_philadelphia,
                oc: settings.data[0].acf.phone_orange_county,
                orlando: settings.data[0].acf.phone_orlando,
                seattle: settings.data[0].acf.phone_seattle,
              }}
              email={settings.data[0].acf.email}
              logos={{
                header: settings.data[0].acf.header_logo,
                footer: settings.data[0].acf.footer_logo,
                badges: settings.data[0].acf.badges,
              }}
              why_we={{
                sd: settings.data[0].acf.why_we_content.san_diego,
                lv: settings.data[0].acf.why_we_content.las_vegas,
                portland: settings.data[0].acf.why_we_content.portland,
                la: settings.data[0].acf.why_we_content.los_angeles,
                denver: settings.data[0].acf.why_we_content.denver,
                dallas: settings.data[0].acf.why_we_content.dallas,
                austin: settings.data[0].acf.why_we_content.austin,
                sacramento: settings.data[0].acf.why_we_content.sacramento,
                philadelphia: settings.data[0].acf.why_we_content.philadelphia,
                oc: settings.data[0].acf.why_we_content.orange_county,
                orlando: settings.data[0].acf.why_we_content.orlando,
                seattle: settings.data[0].acf.why_we_content.seattle,
              }}
              services={data
                ?.filter((el) => el.title.rendered !== 'Appliance Repair')
                .map(
                  (el) =>
                    [
                      {
                        title: String(el.title.rendered),
                        slug: String(el.slug),
                        icon: el.acf.icon,
                      },
                    ][0],
                )}
              reviews={settings.data[0].acf.city_reviews}
              areas={settings.data[0].acf.areas}
              areas_image={settings.data[0].acf.areas}
              brands={settings.data[0].acf.brands}
            />
          }
        />
      ))}

      <Route
        path="/book-now"
        element={
          <BookNow
            phones={{
              sd: settings.data[0].acf.phone_san_diego,
              lv: settings.data[0].acf.phone_las_vegas,
              portland: settings.data[0].acf.phone_portland,
              la: settings.data[0].acf.phone_los_angeles,
              denver: settings.data[0].acf.phone_denver,
              dallas: settings.data[0].acf.phone_dallas,
              austin: settings.data[0].acf.phone_austin,
              sacramento: settings.data[0].acf.phone_sacramento,
              philadelphia: settings.data[0].acf.phone_philadelphia,
              oc: settings.data[0].acf.phone_orange_county,
              orlando: settings.data[0].acf.phone_orlando,
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
            services={data
              ?.filter((el) => el.title.rendered !== 'Appliance Repair')
              .map(
                (el) =>
                  [
                    {
                      title: String(el.title.rendered),
                      slug: String(el.slug),
                      icon: el.acf.icon,
                    },
                  ][0],
              )}
          />
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <LegalPage
            title="Privacy Policy"
            data={settings.data[0].acf.privacy_policy}
            phones={{
              sd: settings.data[0].acf.phone_san_diego,
              lv: settings.data[0].acf.phone_las_vegas,
              portland: settings.data[0].acf.phone_portland,
              la: settings.data[0].acf.phone_los_angeles,
              denver: settings.data[0].acf.phone_denver,
              dallas: settings.data[0].acf.phone_dallas,
              austin: settings.data[0].acf.phone_austin,
              sacramento: settings.data[0].acf.phone_sacramento,
              philadelphia: settings.data[0].acf.phone_philadelphia,
              oc: settings.data[0].acf.phone_orange_county,
              orlando: settings.data[0].acf.phone_orlando,
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/accessibility-statement"
        element={
          <LegalPage
            title="Accessibility Statement"
            data={settings.data[0].acf.accessibility_statement}
            phones={{
              sd: settings.data[0].acf.phone_san_diego,
              lv: settings.data[0].acf.phone_las_vegas,
              portland: settings.data[0].acf.phone_portland,
              la: settings.data[0].acf.phone_los_angeles,
              denver: settings.data[0].acf.phone_denver,
              dallas: settings.data[0].acf.phone_dallas,
              austin: settings.data[0].acf.phone_austin,
              sacramento: settings.data[0].acf.phone_sacramento,
              philadelphia: settings.data[0].acf.phone_philadelphia,
              oc: settings.data[0].acf.phone_orange_county,
              orlando: settings.data[0].acf.phone_orlando,
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <LegalPage
            title="Terms Of Use"
            data={settings.data[0].acf.terms_of_use}
            phones={{
              sd: settings.data[0].acf.phone_san_diego,
              lv: settings.data[0].acf.phone_las_vegas,
              portland: settings.data[0].acf.phone_portland,
              la: settings.data[0].acf.phone_los_angeles,
              denver: settings.data[0].acf.phone_denver,
              dallas: settings.data[0].acf.phone_dallas,
              austin: settings.data[0].acf.phone_austin,
              sacramento: settings.data[0].acf.phone_sacramento,
              philadelphia: settings.data[0].acf.phone_philadelphia,
              oc: settings.data[0].acf.phone_orange_county,
              orlando: settings.data[0].acf.phone_orlando,
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/thank-you"
        element={
          <ThankYou
            title="Thank You Page"
            seo={settings.data[0].acf.ga}
            phones={{
              sd: settings.data[0].acf.phone_san_diego,
              lv: settings.data[0].acf.phone_las_vegas,
              portland: settings.data[0].acf.phone_portland,
              la: settings.data[0].acf.phone_los_angeles,
              denver: settings.data[0].acf.phone_denver,
              dallas: settings.data[0].acf.phone_dallas,
              austin: settings.data[0].acf.phone_austin,
              sacramento: settings.data[0].acf.phone_sacramento,
              philadelphia: settings.data[0].acf.phone_philadelphia,
              oc: settings.data[0].acf.phone_orange_county,
              orlando: settings.data[0].acf.phone_orlando,
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
