import './satisfaction.scss';

const Satisfaction = () => (
  <section className="satisfaction">
    <div className="container">
      <h2 className="section-title">100% satisfaction guaranteed</h2>

      <div className="satisfaction__list">
        <div>
          <img src="./images/google-full.svg" alt="Google Rating" />
          <img src="./images/orange-stars.svg" alt="Stars" />
        </div>

        <div>
          <img src="./images/facebook-full.svg" alt="Facebook Rating" />
          <img src="./images/orange-stars.svg" alt="Stars" />
        </div>

        <div>
          <img src="./images/yelp-full.svg" alt="Yelp Rating" />
          <img src="./images/orange-stars.svg" alt="Stars" />
        </div>
      </div>
    </div>
  </section>
);

export default Satisfaction;
