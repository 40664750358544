import { BookingForm } from '@components';

import './form.scss';

const Form = ({ preSelect, services }) => (
  <section className="book-now">
    <div className="container">
      <BookingForm preSelect={preSelect} services={services} title="WHAT CAN WE DO FOR YOU?" />
    </div>
  </section>
);

export default Form;
